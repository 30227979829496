import React from "react"
import Seo from "../components/Seo"

const WorksPage = () => {
  return (
    <>
      <Seo title="Works" />
    </>
  )
}

export default WorksPage
